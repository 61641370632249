import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Transaction from "./pages/Transaction";
import Transactions from "./pages/Transactions";
import Login from "./pages/Login";
import Players from "./pages/Players";
import PlayerDetailsPage from "./pages/PlayerDetailsPage";
import WithdrawalRequests from "./pages/WithdrawalRequests";
import WithdrawalRequestDetails from "./pages/WithdrawalRequestDetails";
import Logs from "./pages/Logs";
import BookCodes from "./pages/BookCodes";
import BookingCodeDetails from "./pages/BookingCodeDetails";
import Tickets from "./pages/Tickets";
import TicketDetails from "./pages/TicketDetails";
import NotFoundPage from "./pages/NotFoundPage";
import Admins from "./pages/Admins";
import AddAdmin from "./pages/AddAdmin";
import EditAdmin from "./pages/EditAdmin";
import AdminTransactions from "./pages/AdminTransactions";
import Agents from "./pages/Agents";
import AddAgent from "./pages/AddAgent";
import AgentDetailsPage from "./pages/AgentDetailsPage";
import Banners from "./pages/Banners";
import AddBanner from "./pages/AddBanner";
import EditBanner from "./pages/EditBanner";
import GameInstances from "./pages/GameInstances";
import GameInstanceDetails from "./pages/GameInstanceDetails";
import EditGameInstance from "./pages/EditGameInstance";
import AgentRoles from "./pages/AgentRoles";
import AdminRoles from "./pages/AdminRoles";
import AvatarSettings from "./pages/AvatarSettings";
import LotteryImagesSettings from "./pages/LotteryImagesSettings";
import BonusConfigurations from "./pages/BonusConfigurations";
import EditBonusConfiguration from "./pages/EditBonusConfiguration";
import BonusDetails from "./pages/BonusDetails";
import SiteSettings from "./pages/SiteSettings";
import AddGame from "./pages/AddGame";
import Games from "./pages/Games";
import DeviceAnalytics from "./pages/DeviceAnalytics";
import AdminDetails from "./pages/AdminDetails";
import StaticPages from "./pages/StaticPages";
import AddStaticPage from "./pages/AddStaticPage";
import StaticPageDetails from "./pages/StaticPageDetails";
import SiteSettingsDetails from "./pages/SiteSettingsDetails";
import UpdateSiteSetting from "./pages/UpdateSiteSetting";
import AddBonusConfiguration from "./pages/AddBonusConfiguration";
import AdminLogs from "./pages/AdminLogs";
import AddGameInstance from "./pages/AddGameInstance";
import EditGame from "./pages/EditGame";
import GameDetailsPage from "./pages/GameDetailsPage";
import AddHowItWorks from "./pages/AddHowItWorks";
import AddMastersPage from "./pages/AddMastersPage";
import EditStaticPage from "./pages/EditStaticPage";
import Reports from "./pages/Reports";
import Payouts from "./pages/Payouts";
import AgentsCommission from "./pages/AgentsCommission";
import PlayerReports from "./pages/PlayerReports";
import AgentsReports from "./pages/AgentsReports";
import SingleAgentReports from "./pages/SingleAgentReports";
import SinglePlayerReports from "./pages/SinglePlayerReports";
import AddInfluencer from "./pages/AddInfluencer";
import Influencers from "./pages/Influencers";
import CommissionReports from "./pages/CommissionReports";
import BonusTickets from "./pages/BonusTickets";
import ExcludeBetTypes from "./pages/ExcludeBetTypes";
import AddBundleBonus from "./pages/AddBundleBonus";
import VirtualAgents from "./pages/VirtualAgents";

//This is just a random comment
const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* 
                   
          
        <Route
          path="static-page/edit-dynamic-page/:contentId"
          element={<EditDynamicHeader />}
        />
        <Route
          path="static-page/page-detail/:contentId"
          element={<HeaderDetails />}
        />
           <Route path="static-pages/view-headers" element={<AllHeaders />} />
              <Route path="add-header" element={<AddHeader />} />
        <Route
          path="configurations/site-settings/addsetting"
          element={<AddSiteConfig />}
        />
               <Route path="/agents/commission" element={<AgentsCommission />} />                           
          
      */}

        <Route
          path="static-page/edit-static-page/:contentId"
          element={<EditStaticPage />}
        />
        <Route
          path="/games/add-how-it-works/:lotterySlug"
          element={<AddHowItWorks />}
        />
        <Route
          path="/games/add-masters-page/:lotterySlug"
          element={<AddMastersPage />}
        />
        <Route
          path="configurations/bonus/add-bonus"
          element={<AddBonusConfiguration />}
        />
        <Route
          path="games/more-details/:lotteryId"
          element={<GameDetailsPage />}
        />
        <Route path="games/edit-game/:lotteryId" element={<EditGame />} />
        <Route
          path="games/create-game-instance/:lotteryId/:gameCategory"
          element={<AddGameInstance />}
        />
        <Route path="/admins/admin-logs/:adminId" element={<AdminLogs />} />
        <Route
          path="/configurations/site-settings/update-details/:settingId"
          element={<UpdateSiteSetting />}
        />
        <Route
          path="/configurations/site-settings/settings-details/:settingId"
          element={<SiteSettingsDetails />}
        />
        <Route
          path="static-page/more-detail/:contentId"
          element={<StaticPageDetails />}
        />
        <Route
          path="configurations/bonus/bonus-detail/:bonusId"
          element={<BonusDetails />}
        />
        <Route
          path="configurations/bonus/edit-bonus-detail/:bonusId"
          element={<EditBonusConfiguration />}
        />
        <Route
          path="games-instance/edit-game/:gameId/:gameCategory"
          element={<EditGameInstance />}
        />
        <Route
          path="games-instance/more-details/:gameId"
          element={<GameInstanceDetails />}
        />
        <Route path="banners/edit-banner/:bannerId" element={<EditBanner />} />
        <Route
          path="/agents/agent-details/:userId"
          element={<AgentDetailsPage />}
        />
        <Route
          exact
          path="/admins/edit-admin/:adminId"
          element={<EditAdmin />}
        />
        <Route
          path="/admins/more-details/:adminId"
          element={<AdminDetails />}
        />
        <Route
          path="/player-reports/single-player/:userId/:userName"
          element={<SinglePlayerReports />}
        />
        <Route
          path="/agent-reports/single-player/:userId/:userName"
          element={<SingleAgentReports />}
        />
        <Route
          path="/players/player-details/:userId"
          element={<PlayerDetailsPage />}
        />
        <Route
          path="tickets/ticket-details/:ticketId"
          element={<TicketDetails />}
        />
        <Route
          path="bookedcodes/ticket-details/:ticketId/:bookingCode"
          element={<BookingCodeDetails />}
        />
        <Route
          path="/withdrawal-requests/withdrawal-request-details/:requestId"
          element={<WithdrawalRequestDetails />}
        />
        <Route
          path="/transactions/transaction-details/:transactionId"
          element={<Transaction />}
        />
        <Route
          path="/agents/exclude-bettypes/:agentId"
          element={<ExcludeBetTypes />}
        />
        <Route path="games/add-lottery" element={<AddGame />} />
        <Route path="banners/add" element={<AddBanner />} />
        <Route path="influencers/add-bundle" element={<AddBundleBonus />} />
        <Route path="influencers/add" element={<AddInfluencer />} />
        <Route path="commission-reports" element={<CommissionReports />} />
        <Route path="roles/agentroles" element={<AgentRoles />} />
        <Route path="roles/adminroles" element={<AdminRoles />} />
        <Route path="games-instance" element={<GameInstances />} />
        <Route path="banners" element={<Banners />} />
        <Route path="influencers" element={<Influencers />} />
        <Route path="virtual-agents" element={<VirtualAgents />} />

        <Route
          path="/configurations/site-settings"
          element={<SiteSettings />}
        />
        <Route path="player-reports" element={<PlayerReports />} />
        <Route path="agent-reports" element={<AgentsReports />} />

        <Route path="payouts" element={<Payouts />} />
        <Route path="reports" element={<Reports />} />
        <Route path="device-analytics" element={<DeviceAnalytics />} />
        <Route path="admins-transactions" element={<AdminTransactions />} />
        <Route path="agents/add" element={<AddAgent />} />
        <Route path="tickets" element={<Tickets />} />
        <Route path="bonus-tickets" element={<BonusTickets />} />
        <Route path="/configurations/bonus" element={<BonusConfigurations />} />

        <Route
          path="configurations/avatar-settings"
          element={<AvatarSettings />}
        />
        <Route
          path="configurations/lottery-images"
          element={<LotteryImagesSettings />}
        />
        <Route path="agents/commission" element={<AgentsCommission />} />

        <Route path="add-static-page" element={<AddStaticPage />} />
        <Route path="static-pages/all-pages" element={<StaticPages />} />
        <Route path="admins" element={<Admins />} />
        <Route path="admins/add" element={<AddAdmin />} />
        <Route path="bookedcodes" element={<BookCodes />} />
        <Route path="games" element={<Games />} />
        <Route path="agents" element={<Agents />} />
        <Route path="withdrawal-requests" element={<WithdrawalRequests />} />
        <Route path="transactions" element={<Transactions />} />
        <Route path="logs" element={<Logs />} />
        <Route path="players" element={<Players />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="/" element={<Navigate to={"/dashboard"} replace />} />
        <Route path="login" element={<Login />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
