import jwtDecode from "jwt-decode";
import { createBrowserHistory } from "history";
import axios from "axios";

const history = createBrowserHistory();

export const TOKEN_NAME = "auth";
export const PREV_PATH = "PREV_PATH";
export const CASH_OUT = "cash-out";
export const EAZY_WIN = "eazy-win";
export const LOTTO = "lotto";
export const METRO = "metro";

export const GHANA_GAME = "ghana-game";
export const GHANA_GAME_BETOPTIONS = "ghana-game-bet-options";
export const GHANA_GAME_RESULTOPTIONS = "ghana-game-result-types";
export const GHANA_GAME_OVERUNDER_OPTIONS = "ghana-game-over-under-bet-options";

export const GHANA_GAME_V2 = "ghana-game-v2";
export const GHANA_GAME_V2_BETOPTIONS = "ghana-game-v2-bet-options";
export const GHANA_GAME_V2_RESULTOPTIONS = "ghana-game-v2-result-types";
export const GHANA_GAME_V2_OVERUNDER_OPTIONS =
  "ghana-game-v2-over-under-bet-options";

export const FIVE_OF_NINETY = "5of90";
export const FIVE_OF_NINETY_RESULT_OPTIONS = "five-ninety-result-types";
export const FIVE_OF_NINETY_BET_OPTIONS = "five-ninety-bet-options";
export const FIVE_OF_NINETY_OVER_OPTIONS = "over-bet-options";
export const FIVE_OF_NINETY_UNDER_OPTIONS = "under-bet-options";
export const FIVE_OF_NINETY_BOOSTER_OPTIONS = "five-ninety-booster-options";

export const META4 = "meta4";
export const META4_BETOPTIONS = "meta4-bet-options";
export const META4_UNDER_OPTIONS = "meta4-under-bet-options";
export const META4_OVER_OPTIONS = "meta4-over-bet-options";
export const PERFECT_BALLS = "perfect-balls";
export const PERFECT_BALLS_BET_OPTIONS = "perfect-balls-bet-options";

export const SEVEN49 = "seven49";
export const SEVEN49_BETOPTIONS = "seven49-bet-options";
export const SEVEN49_OVEROPTIONS = "seven49-over-bet-options";
export const SEVEN49_UNDEROPTIONS = "seven49-under-bet-options";

export const MERRY_INDOOR_GAME = "merry-indoor-game";
export const MERRY_INDOOR_GAME_BETOPTIONS = "merry-indoor-game-bet-options";
export const MERRY_INDOOR_GAME_RESULT_OPTIONS =
  "merry-indoor-game-result-types";

export const MERRY_INDOOR_GAME_V2 = "merry-indoor-game-v2";
export const MERRY_INDOOR_GAME_V2_BETOPTIONS =
  "merry-indoor-game-v2-bet-options";
export const MERRY_INDOOR_GAME_V2_RESULT_OPTIONS =
  "merry-indoor-game-v2-result-types";

export const POOL_OF_FAME = "pool-of-fame";
export const POOL_OF_FAME_BETOPTIONS = "pool-of-fame-bet-options";

export const OVER_BET_OPTIONS = "over-bet-options";
export const UNDER_BET_OPTIONS = "under-bet-options";
export const DYNAMIC_HEADINGS = "dynamic-headings";

export const DAILY = "DAILY";
export const WEEKLY = "WEEKLY";
export const MONTHLY = "MONTHLY";

export const BUNDLE_BONUS = "bundle-bonus";
export const NORMAL_BONUS = "normal-bonus";
export const formatBonusType = (type = NORMAL_BONUS) => {
  if (type === BUNDLE_BONUS) return "Bundle Bonus";
  return "Normal Bonus";
};
export const getAuthToken = () => {
  let name = TOKEN_NAME + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  // console.log("decodedCookie", decodedCookie);
  setDefaultHeaders();
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      // console.log("c", c.substring(1));
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export function deleteCookie() {
  if (getAuthToken()) {
    document.cookie =
      TOKEN_NAME + "=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT";
  }
}

export const checkExpiredAuthToken = () => {
  const pathname = history?.location?.pathname;
  try {
    const token = getAuthToken();

    if (token) {
      const decodedToken = jwtDecode(token);

      const tokenExpired = decodedToken.exp < new Date().getTime() / 1000;
      if (tokenExpired) {
        //  console.log("Your Login Session Have Expired");
        deleteCookie();
        return history.push("/login", {
          state: {
            prevPath: pathname,
          },
        });
      }
    }
  } catch (error) {
    console.log("err", error);
    deleteCookie();
    return history.push("/login", {
      state: {
        prevPath: pathname,
      },
    });
  }
};

export const logOutUser = () => {
  sessionStorage.setItem(PREV_PATH, history?.location?.pathname);
  deleteCookie();

  return (window.location.href = "/login");
};

export const labelStyles = {
  paddingLeft: 0,
  marginLeft: 0,
  marginBottom: "-9px",
  fontSize: "13.5px",
};
export const labelStylesTwo = {
  paddingLeft: 0,
  marginLeft: 0,
  marginBottom: "7.5px",
  fontSize: "13.5px",
};
export const labelStylesThree = {
  paddingLeft: 0,
  marginLeft: 0,
  marginBottom: "65px",
  fontSize: "13.5px",
};

export const seeMoreBoxStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: "5px 0px",
};

export const menuItemsStyles = { fontSize: "12.7px" };
export const dialogLoaderStyles = { width: "330px", minWidth: "330px" };
export const headerStyles = { fontWeight: 600 };
export const previewSubTitleStyles = { fontWeight: 600 };
export const boldTextCalculationStyles = { fontWeight: 600 };
export const dialogMainTextStyles = { color: "black" };
export const previewDialogMainTitleStyle = {
  textAlign: "center",
  fontWeight: 600,
  textTransform: "uppercase",
};
export const singleDetailsBoxStyle = {
  maxWidth: "500px",
  display: "flex",
  justifyContent: "space-between",
  borderBottom: "1px solid #eee",
};

export const singleDetailsBoxStyleTwo = {
  maxWidth: "800px",
  display: "flex",
  justifyContent: "space-between",
  borderBottom: "1px solid #eee",
};

export const AddButtonBoxStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  marginBottom: "5px",
  marginTop: "5px",
};

export const tableButtonStyles = {
  textTransform: "capitalize",
  fontSize: "12px",
  padding: "2px",
};
export const detailsActionBoxStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

export const csvBoxStyle = {
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  marginBottom: "5px",
};
export const imagePreviewStyle = {
  objectFit: "contain",
};
export const imageBox = {
  display: "flex",
  justifyContent: "space-between",
};
export const IMAGE_PREVIEW_WIDTH = 70;
export const IMAGE_PREVIEW_HEIGHT = 70;
export const csvLinkStyle = { textDecoration: "none" };
export const userRoles = [
  {
    title: "Ordinary User",
    value: "ordinary User",
  },
  {
    title: "First Class User",
    value: "first class user",
  },
  {
    title: "VIP User",
    value: "vip User",
  },
];
export const agentRoles = [
  {
    title: "Cashier",
    value: "cashier",
  },
  {
    title: "Ordinary Agent",
    value: "ordinaryagent",
  },
  {
    title: "Super Agent",
    value: "superagent",
  },
  {
    title: "Principal Agent",
    value: "principalagent",
  },
];
export const BannerCategories = [
  {
    title: "Top-Desktop",
    value: "top-desktop",
  },
  {
    title: "Top-Mobile",
    value: "top-mobile",
  },
  {
    title: "Left-Desktop",
    value: "left-desktop",
  },
  {
    title: "Right-Desktop",
    value: "right-desktop",
  },

  {
    title: "Box-mobile",
    value: "box-mobile",
  },
  {
    title: "Slider",
    value: "slider",
  },
];
export const ProviderList = [
  {
    title: "InterSwitch",
    value: "interswitch",
  },
  {
    title: "PayStack",
    value: "paystack",
  },
  {
    title: "Source",
    value: "source",
  },
];
export const WeekdayList = [
  {
    title: "0 - Sunday",
    day: "Sunday",
    value: 0,
  },
  {
    title: "1 - Monday",
    day: "Monday",
    value: 1,
  },
  {
    title: "2 - Tuesday",
    day: "Tuesday",
    value: 2,
  },
  {
    title: "3 - Wednesday",
    day: "Wednesday",
    value: 3,
  },
  {
    title: "4 - Thursday",
    day: "Thursday",
    value: 4,
  },
  {
    title: "5 - Friday",
    day: "Friday",
    value: 5,
  },
  {
    title: "6 - Saturday",
    day: "Saturday",
    value: 6,
  },
];
export const limit = 20;

export const getWalletSum = (array) => {
  let initialValue = 0;

  for (let index = 0; index < array.length; index++) {
    let element = array[index].walletBalance;
    element = parseFloat(element);
    initialValue += element;
  }
  return initialValue.toFixed(2);
};

export const getCommisionSum = (array) => {
  let initialValue = 0;

  for (let index = 0; index < array.length; index++) {
    let element = array[index].commissionBalance;
    element = parseFloat(element);
    initialValue += element;
  }
  return initialValue.toFixed(2);
};

export const getOverdraftAmountSum = (array) => {
  let initialValue = 0;
  for (let index = 0; index < array.length; index++) {
    let element = array[index]?.initialAmount;
    element = parseFloat(element);
    initialValue += element;
  }

  return initialValue.toFixed(2);
};

export const getCommissionSalesSum = (array) => {
  let initialValue = 0;

  for (let index = 0; index < array.length; index++) {
    let element = array[index].totalSales;
    element = parseFloat(element);
    initialValue += element;
  }
  return initialValue.toFixed(2);
};
export const getCommissionSum = (array) => {
  let initialValue = 0;

  for (let index = 0; index < array.length; index++) {
    let element = array[index].totalCommission;
    element = parseFloat(element);
    initialValue += element;
  }
  return initialValue.toFixed(2);
};

export const getTransactionSum = (array) => {
  let initialValue = 0;

  for (let index = 0; index < array.length; index++) {
    let element = array[index].amount;
    element = parseFloat(element);
    initialValue += element;
  }
  return initialValue.toFixed(2);
};

export const getCommissionWalletSum = (array) => {
  let initialValue = 0;

  for (let index = 0; index < array.length; index++) {
    let element = array[index].walletBalance;
    element = parseFloat(element);
    initialValue += element;
  }
  return initialValue.toFixed(2);
};

export const getCommissionWonSum = (array) => {
  let initialValue = 0;

  for (let index = 0; index < array.length; index++) {
    let element = array[index].totalWon;
    element = parseFloat(element);
    initialValue += element;
  }
  return initialValue.toFixed(2);
};
// Function to set default headers
export const setDefaultHeaders = () => {
  axios.defaults.headers.common["x-api-key"] = `${process.env.ADMIN_API_KEY}`;
};

// To safely JSON.parse a string without breaking the application
export const safeJSONParse = (str) => {
  try {
    return JSON.parse(str);
  } catch (error) {
    console.log("err while parseing", error);
    return [];
  }
};

export function currencyFormater(x) {
  if (!x) {
    return 0;
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export const getSumFromObj = (data) => {
  let total = 0;

  for (const item in data) {
    let elem = data[item];
    total += elem;
  }

  return total;
};

export const getStakedAmountSum = (array) => {
  let initialValue = 0;

  for (let index = 0; index < array.length; index++) {
    let element = array[index].totalStakedAmount;
    element = parseFloat(element);
    initialValue += element;
  }
  return initialValue.toFixed(2);
};
export const getWinningAmountSum = (array) => {
  let initialValue = 0;

  for (let index = 0; index < array.length; index++) {
    let element = array[index].totalWinAmount || 0;
    element = parseFloat(element);
    initialValue += element;
  }
  return initialValue.toFixed(2);
};

export const getWindowQueryString = (name) => {
  let result = "";
  if (typeof window === "object") {
    const urlParams = new URLSearchParams(window.location.search);
    result = urlParams.get(name);
  }
  return result;
};
